<template>
    <div>
        <h4 class="bold text-uppercase mb-3">
            <span @click="$router.go(-1)" class="font-weight-bold hand">
                <i class="fas fa-chevron-left mx-1"></i>
                {{ $route.name }}
            </span>
        </h4>
        <div class="row mt-3">
            <div class="col-md-8 col-12">
                <div class="card card-gray">
                    <div class="card-body">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label font-bold">Tên hiển thị menu:</label>
                            <div class="col-sm-8">
                                <input
                                    class="form-control input-light"
                                    type="text"
                                    placeholder="Tên hiển thị cột bên trái"
                                    required=""
                                    v-model="siteUpdate.site_header"
                                />
                            </div>
                        </div>
                        <!-- <div class="form-group row">
                            <label class="col-sm-4 col-form-label font-bold">Tùy chọn giao diện của trang của trang:</label>
                            <div class="col-md col-12">
                                <div class="fb-reaction text-left">
                                    <div class="checkbox" v-for="(them, index) in themes" :key="index">
                                        <label @click="chooseTheme(them)" :class="['skin-theme mr-2', them.class, them.chosen ? 'theme-active' : '']"> </label>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label font-bold">Nhập đường dẫn ảnh favicon:</label>
                            <div class="col-sm-8">
                                <input
                                    class="form-control input-light"
                                    type="text"
                                    placeholder="Nhập đường dẫn ảnh favicon"
                                    required=""
                                    v-model="siteUpdate.favicon"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label font-bold">Nhập đường dẫn của ảnh logo:</label>
                            <div class="col-sm-8">
                                <input
                                    class="form-control input-light"
                                    type="text"
                                    placeholder="Nhập đường dẫn của ảnh logo"
                                    required=""
                                    v-model="siteUpdate.logo"
                                />
                                <button
                                    @click="updateSiteInfo()"
                                    class="btn form-control btn-lg bold btn-dark-blue form-control mt-3"
                                    type="submit"
                                >
                                    Lưu thông tin
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="card card-gray-2 mb-3 h-100">
                    <div class="card-body">
                        <div class="">
                            <h6>
                                <i class="fa fa-info mr-1"></i>
                                Bạn có thể chỉnh sửa các thông tin liên hệ của trang.
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { updateSiteInfo } from "../../../api/admin-agency-lv2"
import { catchError } from "../../../helpers"
export default {
    name: "site-price",
    components: {},
    data() {
        return {
            siteUpdate: {},
            themes: [
                {
                    skin: "skin-gray",
                    class: "theme-1",
                    chosen: false
                },
                {
                    skin: "skin-black",
                    class: "theme-2",
                    chosen: false
                },
                {
                    skin: "skin-brown",
                    class: "theme-3",
                    chosen: false
                },
                {
                    skin: "skin-red",
                    class: "theme-4",
                    chosen: false
                },
                {
                    skin: "skin-pink",
                    class: "theme-5",
                    chosen: false
                },
                {
                    skin: "skin-purple",
                    class: "theme-6",
                    chosen: false
                },
                {
                    skin: "skin-green",
                    class: "theme-7",
                    chosen: false
                },
                {
                    skin: "skin-megna",
                    class: "theme-8",
                    chosen: false
                },
                {
                    skin: "skin-blue",
                    class: "purple-theme",
                    chosen: false
                }
            ],
            chosenTheme: null
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        tools() {
            return this.$store.state.tools.tools
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        reload() {
            return this.$store.state.app.reload
        },
        currency() {
            return this.$store.state.agency.site.currency || "Xu"
        },
        site() {
            return this.$store.state.agency.site
        },
        agency() {
            return this.$store.state.agency
        }
    },
    created() {
        this.siteUpdate = Object.assign({}, this.site)
        this.themes.forEach(theme => {
            if (this.site.theme == theme.skin) {
                theme.chosen = true
                this.chosenTheme = theme.skin
            } else theme.chosen = false
        })
    },
    methods: {
        chooseTheme: async function(item) {
            this.themes.forEach(theme => {
                if (item == theme) {
                    this.site.theme = theme.skin
                    this.chosenTheme = theme.skin
                    this.$store.commit("STORE_SET_SITE_ID", this.site)
                    theme.chosen = true
                } else theme.chosen = false
            })
        },
        updateSiteInfo: async function() {
            let data = await updateSiteInfo(this.siteUpdate)
            if (data.status === 200 && data.success) {
                this.$swal("Thành Công", data.message, "success")
                this.$store.dispatch("GET_SITE_AGENCY_INFO", this.agency.agency_domain)
            } else this.$swal("Lỗi", catchError(data), "error")
        }
    }
}
</script>
<style>
.theme-active {
    border: 4px solid #ffc100;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}
.skin-theme {
    width: 50px;
    height: 50px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    --border-radius: 25px;
    border-radius: 25px;
}
.theme-1 {
    background-color: #324a5e;
}
.theme-2 {
    background-color: #333333;
}
.theme-3 {
    background-color: #6b351d;
}
.theme-4 {
    background-color: #cb0f47;
}
.theme-5 {
    background-color: #a51065;
}
.theme-6 {
    background-color: #9b51e0;
}
.theme-7 {
    background-color: #219653;
}
.theme-8 {
    background-color: #007dc4;
}
.purple-theme {
    background-color: #352d7d;
}
</style>
